import { fetchGet } from '../utils/api'

export const toggle_sidebar = () => {
    return (dispatch) => {
        dispatch({
            type: 'TOGGLE_SIDEBAR'
        })
    }
}

export const clear_data = () => {
    return (dispatch) => {
        dispatch({
            type: 'JOBS_LIST',
            metrics: []
        })
    }
}

export const fetch_metrics = (startAt, endAt, appointmentAt) => {
    var api = '/metrics/overview/agent'

    if (startAt) {
        if (api.indexOf('startAt') > -1) {
            const replace = api.slice(api.indexOf('startAt'), api.indexOf('startAt') + 16)
            api = api.replace(replace, 'startAt=' + startAt)
        } else {
            api = api.indexOf('?') > -1 ? api + '&startAt=' + startAt : api + '?startAt=' + startAt
        }
    }

    if (endAt) {
        if (api.indexOf('endAt') > -1) {
            const replace = api.slice(api.indexOf('endAt'), api.indexOf('endAt') + 16)
            api = api.replace(replace, 'endAt=' + endAt)
        } else {
            api = api.indexOf('?') > -1 ? api + '&endAt=' + endAt : api + '?endAt=' + endAt
        }
    }

    if (appointmentAt) {
        api = api.indexOf('?') > -1 ? api + '&appointmentAt=' + appointmentAt : api + '?appointmentAt=' + appointmentAt
    }

    return (dispatch) => {
        dispatch({
            type: 'SET_METRICS',
            metrics: {}
        })
        fetchGet(api,
            (data) => {
                dispatch({
                    type: 'SET_METRICS',
                    metrics: data.metrics
                })
            },
            () => {
                dispatch({
                    type: 'SET_METRICS',
                    metrics: {}
                })
            }
        );
    }
}

export const fetch_service_areas = () => {
    return (dispatch) => {
        fetchGet('/utils/service_areas',
            (data) => {
                dispatch({
                    type: 'SERVICE_AREAS_LIST',
                    areas: data
                })
            },
            () => {
                dispatch({
                    type: 'SERVICE_AREAS_LIST',
                    areas: []
                })
            }
        );
    }
}

export const fetch_jobs = (params = {}, page = null) => {
    return (dispatch) => {
        dispatch({
            type: 'JOBS_LIST',
            jobs: []
        })

        var api = '/jobs'
        if (params.search) {
            api = api + '?search=' + params.search
        }
        if (params.status) {
            api = api.indexOf('?') > -1 ? api + '&status=' + params.status : api + '?status=' + params.status
        }
        if (params.priorityEndAt) {
            api = api.indexOf('?') > -1 ? api + '&endAt=' + params.priorityEndAt : api + '?endAt=' + params.priorityEndAt
        }
        if (params.priorityStartAt) {
            api = api.indexOf('?') > -1 ? api + '&startAt=' + params.priorityStartAt : api + '?startAt=' + params.priorityStartAt
        }
        if (params.endAt) {
            if (api.indexOf('endAt') > -1) {
                const replace = api.slice(api.indexOf('endAt'), api.indexOf('endAt') + 16)
                api = api.replace(replace, 'endAt=' + params.endAt)
            } else {
                api = api.indexOf('?') > -1 ? api + '&endAt=' + params.endAt : api + '?endAt=' + params.endAt
            }
        }

        if (params.startAt) {
            if (api.indexOf('startAt') > -1) {
                const replace = api.slice(api.indexOf('startAt'), api.indexOf('startAt') + 16)
                api = api.replace(replace, 'startAt=' + params.startAt)
            } else {
                api = api.indexOf('?') > -1 ? api + '&startAt=' + params.startAt : api + '?startAt=' + params.startAt
            }
        }

        if (params.appointmentAt) {
            api = api.indexOf('?') > -1 ? api + '&appointmentAt=' + params.appointmentAt : api + '?appointmentAt=' + params.appointmentAt
        }

        if (params.area) {
            api = api.indexOf('?') > -1 ? api + '&service=' + params.area : api + '?service=' + params.area
        }

        if (params.priority) {
            api = api.indexOf('?') > -1 ? api + '&priority=' + params.priority : api + '?priority=' + params.priority
        }

        if (params.page) {
            api = api.indexOf('?') > -1 ? api + '&page=' + params.page : api + '?page=' + params.page
        }

        if (params.completedAt) {
            api = api.indexOf('?') > -1 ? api + '&completedAt=today' : api + '?completedAt=today'
        }

        if (params.orderBy) {
            api = api.indexOf('?') > -1 ? api + '&orderBy=' + params.orderBy : api + '?orderBy=' + params.orderBy
        }
        if (params.insure_type) {
            api = api.indexOf('?') > -1 ? api + '&insure_type=' + params.insure_type : api + '?insure_type=' + params.insure_type
        }

        fetchGet(api,
            (body) => {
                dispatch({
                    type: 'JOBS_LIST',
                    jobs: body.data || [],
                    pagination: body.pagination
                })
            },
            () => {
                dispatch({
                    type: 'JOBS_LIST',
                    jobs: []
                })
            },
            true
        );
    }
}

export const fetch_invoices = (params, page) => {

    var api = '/jobs/invoices'
    if (params.search) {
        api = api + '?search=' + params.search
    }
    if (page) {
        api = api.indexOf('?') > -1 ? api + '&page=' + page : api + '?page=' + page
    }

    return (dispatch) => {
        dispatch({
            type: 'INVOICES_LIST',
            jobs: []
        })
        fetchGet(api,
            (data) => {
                dispatch({
                    type: 'INVOICES_LIST',
                    invoices: data
                })
            },
            () => {
                dispatch({
                    type: 'INVOICES_LIST',
                    invoices: []
                })
            }
        );
    }
}
