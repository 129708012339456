import Config from '../config';
import { store } from '../store';

export function getUUID() {
    return store.getState().user ? store.getState().user.uuid : null
}

export function getUser() {
    return store.getState().user ? store.getState().user['full_name'] : null
}

export function getAuth() {
    const sponsor = store.getState().user ? store.getState().user['sponsor'] : null
    const org = store.getState().user ? store.getState().user['service_org'] : null
    if (sponsor && org) {
        return sponsor + ':' + org
    }
    return null
}

function doFetch(path, method, body, callback, fallback, returnBody = false) {
    // const auth = crypto.createHmac(`${moment().tz('Africa/Johannesburg').format('YYYY-MM-DD')}`, getUUID()).toString();
    var options = {
        headers: {
            'Authorization': getAuth(),
            'X-UUID': getUUID(),
            'X-USER': getUser(),
            'Content-Type': 'application/json'
        },
        method: method
    }

    if (method === 'POST') {
        options.body = JSON.stringify(body)
        options.data = JSON.stringify(body)
    }

    fetch(Config.API_BASE + path, options)

        // fetch(Config.API_BASE + path, options)
        .then(response => {
            return response.json()
        })
        .then((response) => {
            if (response.status === 200) {
                if (returnBody) {
                    callback(response)
                } else {
                    callback(response.data)
                }
            } else {
                fallback(response)
            }
        });
}

export function fetchPost(path, data, callback, fallback) {
    doFetch(path, 'POST', data, callback, fallback)
}

export function fetchGet(path, callback, fallback, returnBody) {
    doFetch(path, 'GET', null, callback, fallback, returnBody)
}

export function fetchDownload(path, callback, fallback) {
    var options = {
        headers: {
            'Authorization': getAuth(),
            'X-UUID': getUUID(),
            'X-USER': getUser(),
            'Content-Type': 'application/json'
        },
        method: 'GET'
    }

    fetch(Config.API_BASE + path, options)
        .then(response => {
            return response.blob()
        })
        .then((response) => {
            callback(response)
        });
}