import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import JobDetails from "../components/jobs/JobDetails";

import { withRouter } from 'react-router-dom';

class AddEditJob extends React.Component {
    state = {
        data: {},
        showClose: false,
        currentState: {},
        scroll: null
    }

    componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if (this.props.location.state) {
            const state = this.props.location.state || {}
            this.setState({
                data: state.row || {},
                currentState: state.currentState || null,
                scroll: state.scroll || null
            })
        }
    }

    static getDerivedStateFromProps(props) {
        if (!props.location.state) {
            props.history.push('/jobs')
        }
        const state = props.location.state
        return {
            data: state.row || {},
            currentState: state.currentState || null,
            scroll: state.scroll || null
        }
    }

    render() {
        const { currentState, scroll } = this.state

        return (
            <Container fluid className="main-content-container px-4">
                <Row noGutters className="page-header py-4">
                    <PageTitle title="Jobs" subtitle={`No. ${this.state.data['job_no']}`} md="12"
                        className="ml-sm-auto mr-sm-auto"
                        onBack={() => {
                            this.props.history.push('/jobs', {
                                currentState: currentState,
                                scroll: scroll
                            })
                        }}
                    />
                </Row>
                <Row>
                    <Col lg="12">
                        <JobDetails
                            data={this.state.data}
                            history={this.props.history}
                            onBack={() => {
                                this.props.history.push('/jobs', {
                                    currentState: currentState,
                                    scroll: scroll
                                })
                            }}
                        />
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default withRouter(AddEditJob)
