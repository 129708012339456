import React from 'react';
import {
  Dropdown,
  NavItem
} from 'shards-react';

import { connect } from 'react-redux';

class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name : '',
      userType: '',
      visible: false
    };

    this.toggleSponsorAction = this.toggleSponsorAction.bind(this);
  }

  toggleSponsorAction() {
    this.setState({
      visible: !this.state.visible
    });
  }

  render() {
    return (

      <NavItem tag={Dropdown} caret toggle={this.toggleSponsorAction}> 
        {this.props.user && <div className='d-flex text-nowrap px-3' style={{ padding: '.625rem .625rem' }}>
            <ul>
              {this.props.user['sponsor_name'] && <li><b>Sponsor: </b> {this.props.user['sponsor_name']}</li>}
              {this.props.user['remote_id'] && <li><b>Role: </b> Agent</li>}
            </ul>
        </div>}
      </NavItem>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state,
    user: state.user
  }
}

export default connect(mapStateToProps)(UserActions)
