import React from 'react';
import { Nav } from 'shards-react';

import SidebarNavItem from './SidebarNavItem';
import SidebarNavInnerItem from './SidebarNavInnerItem';
import { toggle_sidebar } from '../../../store/action';
import { connect } from 'react-redux';

const navItems = [
  {
    title: "Dashboard",
    to: "/",
    htmlBefore: '<i class="material-icons">home</i>',
    htmlAfter: ""
  },
  {
    title: "Service Calls",
    htmlBefore: '<i class="material-icons">confirmation_number</i>',
    to: "/jobs",
  },
  {
    title: "Sign Out",
    htmlBefore: '<i class="material-icons">exit_to_app</i>',
    to: "/auth/signin",
  }
];


class SidebarNavItems extends React.Component {
  state = {
    activeRoute: null
  }

  static getDerivedStateFromProps(props) {
    return {
      user: props.user
    }
  }

  handleToggleSidebar() {
    if (this.props.toggle_sidebar) {
      this.props.toggle_sidebar()
    }
  }

  onRouteActive(route) {
    if (this.state.activeRoute === route) {
      this.setState({
        activeRoute: null
      })
    } else {
      this.setState({
        activeRoute: route
      })
    }
  }

  renderInnerRoutes(item) {
    return item.routes.map((inner, i) => (
      <SidebarNavInnerItem key={i} item={inner} />
    ))
  }

  render() {
    return (
      <div className='nav-wrapper'>
        <Nav className='flex-column'>
          {navItems.map((item, idx) => {
            if (item.routesInner) {
              return (
                <div key={idx}>
                  <SidebarNavItem key={idx} item={item} onRouteActive={(title) => this.onRouteActive(title)} />
                  {(this.state.activeRoute === item.title) && this.renderInnerRoutes(item)}
                </div>
              )
            }
            if (item.to === '/sign-out') {
              return (
                <div style={{ paddingTop: '60px' }}>
                  <SidebarNavItem key={idx} item={item} noBorder onRouteActive={(title) => this.onRouteActive(title)} />
                </div>
              )
            }
            if (item.to === '/auth/signin') {
              return (
                <div key={idx} style={{ paddingTop: '60px' }}>
                  <SidebarNavItem key={idx} item={item} noBorder onRouteActive={(title) => this.onRouteActive(title)} />
                </div>
              )
            }
            return <SidebarNavItem key={idx} item={item} onToggle={() => this.handleToggleSidebar()} onRouteActive={(title) => this.onRouteActive(title)} />
          })}
        </Nav>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    ...state,
    user: state.user,
    menuVisible: state.menuVisible
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggle_sidebar: () => dispatch(toggle_sidebar())
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(SidebarNavItems);
