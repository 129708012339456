import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Container, Navbar } from 'shards-react';

import NavbarSearch from './NavbarSearch';
import { connect } from 'react-redux';
import NavbarNav from './NavbarNav/NavbarNav';
import NavbarToggle from './NavbarToggle';

const MainNavbar = ({ layout, stickyTop, user }) => {
  const classes = classNames(
    'main-navbar',
    'bg-white',
    stickyTop && 'sticky-top'
  );

  return (
    <div className={classes}>
      <Container className='p-0'>
        <Navbar type='light' className='align-items-stretch flex-md-nowrap p-0'>
          <div className='main-navbar__search w-100 d-none d-md-flex d-lg-flex' />
          <NavbarNav user={user} />
          <NavbarToggle />
        </Navbar>
      </Container>
    </div>
  );
};

MainNavbar.propTypes = {
  /**
   * The layout type where the MainNavbar is used.
   */
  layout: PropTypes.string,
  /**
   * Whether the main navbar is sticky to the top, or not.
   */
  stickyTop: PropTypes.bool
};

MainNavbar.defaultProps = {
  stickyTop: true
};

const mapStateToProps = state => {
  return {
    ...state,
    user: state.user
  }
}

export default connect(mapStateToProps)(MainNavbar);
