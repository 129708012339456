import React from 'react';
import { Collapse, DropdownItem } from 'shards-react';
import { connect } from 'react-redux';
import { fetchGet } from '../../utils/api'




class ServiceAreaFilter extends React.Component {
    state = {
        visible: false,
        areas: []
    }

    componentDidMount() {
        if (this.props.user && this.props.user['uuid']) {
            fetchGet(`/agents/service_areas/${this.props.user['uuid']}`,
                (response) => {
                    this.setState({ areas: response.areas || [] })
                },
                (response) => {
                }
            )
        }
    }

    componentDidUpdate(props) {
        if (this.props.visible !== props.visible) {
            this.setState({ visible: this.props.visible })
        }
    }

    static getDerivedStateFromProps(props) {
        return {
            areas: props.areas || []
        }
    }

    render() {
        var serviceAreas = []

        if(this.props.user && this.props.user.service_org_id.indexOf('ARC') > -1){
            serviceAreas = [
                {
                    code: '01',
                    sponsor: 'k19',
                    description: 'country'
                },
                {
                    code: '02',
                    sponsor: 'k19',
                    description: 'local'
                },
                {
                    code: '03',
                    sponsor: 'k19',
                    description: 'paarl | stellenbosh | somerset west | township'
                },
                {
                    code: '04',
                    sponsor: 'k19',
                    description: "hermanus | pingle | betty's bay | kleinmond"
                },
                {
                    code: '05',
                    sponsor: 'k19',
                    description: 'gansbaai'
                },
                {
                    code: '06',
                    sponsor: 'k20',
                    description: 'pearly beach'
                },
                {
                    code: '07',
                    sponsor: 'k24',
                    description: 'distance from 341km to 400km'
                },
                {
                    code: '08',
                    sponsor: 'k21',
                    description: 'walk in small appliance'
                },
                {
                    code: '09',
                    sponsor: 'k22',
                    description: 'walk in large appliance'
                },
                {
                    code: '10',
                    sponsor: 'k23',
                    description: 'call out'
                },
                {
                    code: null,
                    sponsor: null,
                    description: 'Remove Filter'
                }
            ]
        }

        if(this.props.user && this.props.user.service_org_id.indexOf('ASC') > -1){
            serviceAreas = [
                {
                    code: '01',
                    sponsor: 'k19',
                    description: 'country'
                },
                {
                    code: '02',
                    sponsor: 'k19',
                    description: 'local'
                },
                {
                    code: '03',
                    sponsor: 'k19',
                    description: 'paarl | stellenbosh | somerset west | township'
                },
                {
                    code: '04',
                    sponsor: 'k19',
                    description: "hermanus | pingle | betty's bay | kleinmond"
                },
                {
                    code: '05',
                    sponsor: 'k19',
                    description: 'gansbaai'
                },
                {
                    code: '06',
                    sponsor: 'k20',
                    description: 'pearly beach'
                },
                {
                    code: '07',
                    sponsor: 'k24',
                    description: 'distance from 341km to 400km'
                },
                {
                    code: null,
                    sponsor: null,
                    description: 'Remove Filter'
                }
            ]
        }




        var areas = serviceAreas
        if (this.state.areas && this.state.areas.length > 0) {
            const agentAreas = this.state.areas.map(area => area['code'])
            areas = serviceAreas.filter(area => {
                return agentAreas.indexOf(area['code']) > -1
            })
        }
        areas.push({
            code: null,
            description: 'Remove Filter'
        })

        return (
            <Collapse
                open={this.state.visible}
                className='dropdown-menu dropdown-menu-small'
            >
                {areas.map((area, codex) => {
                    if (area.code) {
                        return (
                            <div key={codex}>
                                <DropdownItem>
                                    <div className='notification__content' onClick={() => this.props.onSelect({
                                        description: area.description,
                                        value: area.code
                                    })}>
                                        <span className='notification__category stats-small__label text-uppercase'>{area.code}: {area.description}</span>
                                        <p className='mb-0 text-capitalize'>
                                            {area.description.replace(/_/g, ' ')}
                                        </p>
                                    </div>
                                </DropdownItem>
                            </div>
                        )
                    }
                    return (
                        <div key={codex}>
                            <div style={{ height: '8px' }}><hr /></div>
                            <DropdownItem>
                                <div className='notification__content' onClick={() => this.props.onSelect({
                                    description: area.description,
                                    value: null
                                })}>
                                    <p className='mb-0 text-capitalize'>
                                        Remove Filter
                                    </p>
                                </div>
                            </DropdownItem>
                        </div>
                    )
                })}
            </Collapse>
        )
    }

}

const mapStateToProps = state => {
    return {
        ...state,
        user: state.user
    }
}

export default connect(mapStateToProps)(ServiceAreaFilter);