import React from "react";
import { Container, Row, Col, Card, Alert } from "shards-react";
import Loader from '../components/common/Loader';

import JobActions from '../components/jobs/JobActions';
import JobTable from '../components/jobs/JobTable';
import { fetchPost } from '../utils/api';
import { colors } from '../utils/colors';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetch_jobs } from '../store/action';

class Jobs extends React.Component {
    state = {
        jobs: [],
        pagination: {},
        loading: true,
        search: null,
        status: null,
        startAt: null,
        endAt: null,
        appointmentAt: null,
        area: null,
        page: 1,
        selected: [],
        refresh: false
    }

    componentDidMount() {
        if (this.props.location.state && this.props.location.state.clear) {
            this.onClearAll()
        }

        if (this.props.location.search) {
            const params = this.props.location.search.substring(1).split('&')
            const state = this.props.location.state ? this.props.location.state.currentState : {}

            params.forEach(param => {
                state[param.split('=')[0]] = param.split('=')[1]
            })
            this.setState(state)
            this.props.fetch_jobs(state)
        } else {
            const state = this.props.location.state ? this.props.location.state.currentState : {}
            this.setState(state)
            this.props.fetch_jobs(state, true)
        }
        if (this.props.location.state && this.props.location.state.scroll) {
            if (window.document.getElementById(this.props.location.state.scroll)) {
                setTimeout(() => {
                    const element = window.document.getElementById(this.props.location.state.scroll)
                    const top = element ? element.offsetTop - 170 : 0
                    window.scrollTo({ top: top, behavior: 'smooth' });
                }, 1200)
            } else {
                this.setState({
                    successMessage: 'Successfully updated'
                })
            }
        }
    }

    static getDerivedStateFromProps(props) {
        return {
            user: props.user,
            jobs: props.jobs || [],
            pagination: props.pagination || {},
            loading: false
        }
    }

    onPage(next = false, prev = false) {
        const state = this.state
        var page = null
        if (next) {
            state['page'] = state.page + 1
            page = state.page + 1
        }
        if (prev && state.page !== 0) {
            state['page'] = state.page - 1
            page = state.page - 1
        }
        this.setState(state)
        delete state['jobs']
        this.props.fetch_jobs(state, true, page)
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    onClearAll() {
        this.setState({
            jobs: [],
            loading: true,
            search: null,
            status: null,
            startAt: null,
            endAt: null,
            appointmentAt: null,
            area: null,
            page: 0,
            selected: [],
            refresh: true,
            message: null
        })
        this.props.fetch_jobs()
    }

    onMultiUpdate(state) {
        fetchPost(`/jobs/multi_update/` + state,
            {
                "jobs": this.state.selected
            },
            (response) => {
                this.setState({
                    jobs: [],
                    loading: true,
                    search: null,
                    status: null,
                    startAt: null,
                    endAt: null,
                    appointmentAt: null,
                    area: null,
                    page: 0,
                    selected: [],
                    refresh: true
                })
                this.props.fetch_jobs()
            },
            (response) => {
                this.setState({ message: response.message })
            }
        )
    }

    onFilter(key, value) {
        const state = this.state
        if (typeof key === 'object') {
            state[key[0]] = value[0]
            state[key[1]] = value[1]
        } else {
            state[key] = value
        }

        state['page'] = 1

        this.setState(state)
        delete state['jobs']
        this.props.fetch_jobs(state, true, null)
    }

    render() {
        const dates = {
            priorityEndAt: this.state.priorityEndAt,
            priorityStartAt: this.state.priorityStartAt,
            endAt: this.state.endAt,
            startAt: this.state.startAt,
        }

        const currentState = {
            ...this.state
        }
        delete currentState['jobs']
        delete currentState['loading']
        delete currentState['showPopup']
        delete currentState['showUpdatePopup']
        delete currentState['user']

        return (
            <Container fluid className="main-content-container px-0 pl-0" style={{ position: 'relative' }}>
                <JobActions
                    showingAll={this.state.showingAll}
                    onShowFiltered={value => this.onFilter('showingAll', value)}
                    onSearch={search => this.onFilter('search', search)}
                    onFilterStatus={filter => {
                        if (this.state.selected.length > 0) {
                            this.onMultiUpdate(filter)
                        } else {
                            this.onFilter('status', filter)
                        }
                    }}
                    onStartAt={startAt => this.onFilter('startAt', startAt)}
                    onEndAt={endAt => this.onFilter('endAt', endAt)}
                    onAppointmentAt={apptAt => this.onFilter('appointmentAt', apptAt)}
                    onFilterService={area => this.onFilter('area', area)}
                    onFilterPriority={priority => this.onFilter(priority.key, priority.value)}
                    onFilterOrderBy={orderBy => this.onFilter('orderBy', orderBy)}
                    onClearAll={() => this.props.history.push('/refresh', { clear: true })}
                    selected={this.state.selected}
                    currentState={currentState}
                    dates={dates}
                    refresh={this.state.refresh}
                    length={this.state.jobs.length}
                />
                <Row className='mx-0'>
                    {this.state.jobs && (
                        <Col>
                            <Card small className="mb-4">
                                {this.state.message && (
                                    <Alert style={{ backgroundColor: colors.RED, color: '#ffffff' }}>
                                        {this.state.message}
                                    </Alert>
                                )}
                                <JobTable
                                    data={this.state.jobs}
                                    onSelected={(selected) => this.setState({ selected: selected })}
                                    refresh={this.state.refresh}
                                    onPrev={() => this.onPage(false, true)}
                                    onNext={() => this.onPage(true)}
                                    count={this.state.pagination.count}
                                    currentState={currentState}
                                    page={this.state.page}
                                />
                            </Card>
                        </Col>
                    )}
                    {!this.state.jobs && <Loader title='Service Calls' />}
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state,
        user: state.user,
        jobs: state.jobs,
        pagination: state.pagination
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_jobs: (params) => dispatch(fetch_jobs(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Jobs));
