import moment from 'moment-timezone';

let defaultState = {
    menuVisible: false
};

const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case 'TOGGLE_SIDEBAR': {
            return { ...state, menuVisible: !state.menuVisible }
        }
        case 'SET_USER': {
            return { ...state, user: action.user, isLoggedIn: true, lastLoggin: moment().format('YYYY-MM-DD') }
        }
        case 'SET_METRICS': {
            return { ...state, metrics: action.metrics || {}, jobs: [] }
        }
        case 'JOBS_LIST': {
            return { ...state, jobs: action.jobs || [], pagination: action.pagination || null }
        }
        case 'INVOICES_LIST': {
            return { ...state, invoices: action.invoices || [] }
        }
        case 'CUSTOMERS_LIST': {
            return { ...state, customers: action.customers || [] }
        }
        case 'SERVICE_AREAS_LIST': {
            console.log('Fetching areas')
            return { ...state, areas: action.areas || [] }
        }
        case 'CLEAR': {
            return { user: null, isLoggedIn: false }
        }
        case 'LOGOUT': {
            return { user: null, isLoggedIn: false }
        }
        case 'persist/REHYDRATE': {
            return { ...state }
        }
        default: {
            return state;
        }
    }
}

export default reducer;