import React from 'react';
import { Collapse, DropdownItem } from 'shards-react';
import { connect } from 'react-redux';


class JobStatusFilter extends React.Component {
    state = {
        visible: false,
        selected: null
    }

    componentDidUpdate(props) {
        if (this.props.visible !== props.visible) {
            this.setState({ visible: this.props.visible })
        }

        if (this.props.selected !== props.selected) {
            this.setState({ selected: this.props.selected })
        }
    }

    render() {
        var statuses = []
        if(this.props.user && this.props.user.service_org_id.indexOf('ARC') > -1){
             statuses = [
                { label: 'In Hand', value: 'in_hand_by_agent' },
                { label: 'Awaiting Parts (Not Ordered)', value: 'awaiting_parts_not_ordered' },
                { label: 'Awaiting Parts (Ordered)', value: 'awaiting_parts_ordered' },
                { label: 'Awaiting Parts (Referred)', value: 'awaiting_parts_refer_to_management' },
                { label: 'Pickup For Workshop Picked Up', value: 'pickup_for_workshop_picked_up' },
                { label: 'Pickup For Workshop Not Picked Up', value: 'pickup_for_workshop_not_picked_up' },
                { label: 'Refer To Management', value: 'refer_to_management' },
                { label: 'Quotation', value: 'quotation' },
                { label: 'Technical Report', value: 'technical_report' },
                { label: 'Completed', value: 'completed' },
                { label: 'Remove Filter', value: null }
            ]
        }

        if(this.props.user && this.props.user.service_org_id.indexOf('ASC') > -1){
            statuses = [
                { label: 'In Hand', value: 'in_hand_by_agent' },
                { label: 'Awaiting Parts (Not Ordered)', value: 'awaiting_parts_not_ordered' },
                { label: 'Awaiting Parts (Ordered)', value: 'awaiting_parts_ordered' },
                { label: 'Awaiting Parts (Referred)', value: 'awaiting_parts_refer_to_defy' },
                { label: 'Pickup For Workshop Not Picked Up', value: 'pickup_for_workshop_not_picked_up' },
                { label: 'Pickup For Workshop', value: 'pickup_for_workshop' },
                { label: 'Refer To Defy', value: 'refer_to_defy' },
                { label: 'Quotation', value: 'quotation' },
                { label: 'Technical Report', value: 'technical_report' },
                { label: 'Completed', value: 'completed' },
                { label: 'Remove Filter', value: null }
            ]
        }

        return (
            <Collapse
                open={this.state.visible}
                className='dropdown-menu dropdown-menu-small'
            >
                {statuses.map(status => {
                    if (status.value) {
                        return (
                            <div key={status.value}>
                                <DropdownItem>
                                    <div className='notification__content' onClick={() => this.props.onSelect(status)}>
                                        <p className='mb-0'>
                                            {status.label}
                                        </p>
                                    </div>
                                </DropdownItem>
                            </div>
                        )
                    }
                    return (
                        <div key={status.value}>
                            <div style={{ height: '8px' }}><hr /></div>
                            <DropdownItem>
                                <div className='notification__content' onClick={() => this.props.onSelect(status)}>
                                    <p className='mb-0'>
                                        Remove Filter
                                    </p>
                                </div>
                            </DropdownItem>
                        </div>
                    )
                })}
            </Collapse>
        )
    }

}


const mapStateToProps = state => {
    return {
        ...state
    }
}

export default connect(mapStateToProps)(JobStatusFilter);