import React from 'react';
import {
    Container,
    Card,
    ListGroup,
    ListGroupItem,
    Row,
    Col,
    Form,
    ButtonGroup,
    FormInput,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Button, Alert, FormCheckbox
} from 'shards-react';

import PageTitle from "../components/common/PageTitle";
import { connect } from 'react-redux';
import { fetchPost } from '../utils/api'
import { withRouter } from 'react-router-dom';
import { fetch_service_areas } from '../store/action';
import { colors } from '../utils/colors';


class MyAccount extends React.Component {
    state = {
        user: {},
        loading: false,
        reset_password: false,
        serviceAreas: []
    }

    static getDerivedStateFromProps(props) {
        return {
            user: props.user || []
        }
    }

    renderJobs(jobs) {
        if (jobs) {
            return jobs.map(job => job)
        }
        return undefined;
    }

    renderServiceAreas() {
        const serviceAreas = [
            {
                id: '01',
                sponsor_area_code: 'k19',
                description: 'country'
            },
            {
                id: '02',
                sponsor_area_code: 'k19',
                description: 'local'
            },
            {
                id: '03',
                sponsor_area_code: 'k19',
                description: 'paarl | stellenbosh | somerset west | township'
            },
            {
                id: '04',
                sponsor_area_code: 'k19',
                description: "hermanus | pingle | betty's bay | kleinmond"
            },
            {
                id: '05',
                sponsor_area_code: 'k19',
                description: 'gansbaai'
            },
            {
                id: '06',
                sponsor_area_code: 'k20',
                description: 'pearly beach'
            },
            {
                id: '07',
                sponsor_area_code: 'k24',
                description: 'distance from 341km to 400km'
            },
            {
                id: null,
                sponsor_area_code: null,
                description: 'Remove Filter'
            }
        ]
    }

    

    render() {
        const data = this.state.user || {}
        const areas = this.props.areas || null
        const handleResetPassword = event => {
            if (event.target.checked) {
                this.setState({reset_password: true})
            } else {
                this.setState({reset_password: false, new_password: null, current_password : null,confirm_password: null})
            }
        };

        console.log(data)
        return (
            <Container fluid className="main-content-container px-4">
                <Row noGutters className="page-header py-4">
                    <PageTitle title="My Account" subtitle='Details' md="12"
                        className="ml-sm-auto mr-sm-auto"
                        onBack={() => this.props.history.goBack()}
                    />
                </Row>
                <Row>
                    <Col lg="12">
                        <Card small className='mb-4'>
                            <ListGroup flush>
                                <ListGroupItem className='p-3'>
                                    <Row>
                                        <Col>
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault()
                                                    var api = ''
                                                    if (data && data['uuid']) {
                                                        api = `/agents/update/${data['uuid']}`
                                                    }
                                                    const state = this.state
                                                    delete state['serviceAreas']
                                                    const agent = {}
                                                    if(this.state.user.contact_mobile){
                                                        agent['contact_mobile'] = this.state.user.contact_mobile
                                                    }
                                                    if(this.state.user.full_name){
                                                        agent['full_name'] = this.state.user.full_name
                                                    }

                                                    if(this.state.current_password){
                                                        agent['current_password'] = this.state.current_password
                                                    }

                                                    if(this.state.new_password || this.state.confirm_password){
                                                        if(this.state.new_password === this.state.confirm_password){
                                                            this.setState({message : 'Passwords do not match'})
                                                            return
                                                        }else{
                                                            agent['password'] = this.state.new_password
                                                        }
                                                    }

                                                    fetchPost(api,
                                                        {
                                                            agent
                                                        },
                                                        (response) => {
                                                            if (response && response.status === 200) {
                                                                this.setState({ message: response.message, loading: false, success: true ,temp:response.temp_password})
                                                            } else {
                                                                this.setState({ message: response.message, loading: false, success: false })
                                                            }
                                                        },
                                                        (response) => {
                                                            this.setState({ message: response.message, loading: false, success: false})
                                                        })
                                                }}
                                            >
                                                <Row form>
                                                    <Col md='3' className='form-group'>
                                                        <label htmlFor='remove_id'>Agent ID</label>
                                                        <FormInput
                                                            id='remove_id'
                                                            placeholder='Optional'
                                                            disabled
                                                            value={data['remote_id']}
                                                        />
                                                    </Col>
                                                    <Col md='3' className='form-group'>
                                                        <label htmlFor='service_org'>Sponsor Name</label>
                                                        <FormInput
                                                            id='service_org'
                                                            placeholder='Service Org'
                                                            disabled
                                                            value={data['sponsor_name']}
                                                        />
                                                    </Col>
                                                    <Col md='4' className='form-group'>
                                                        <label htmlFor='full_name'>Full Name <span style={{color:"green"}}><strong>[{data['full_name'] || ''}]</strong></span></label>
                                                        <FormInput
                                                            id='full_name'
                                                            placeholder='Full Name'
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col md='3' className='form-group'>
                                                        <label htmlFor='contact_mobile'>Mobile No.  <span style={{color:"green"}}><strong>[{data['contact_mobile'] || ''}]</strong></span></label>
                                                        <FormInput
                                                            id='contact_mobile'
                                                            placeholder='Contact Phone'
                                                            onChange={(e) => {
                                                                this.setState({ mobile: e.target.value })
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col md='3' className='form-group'>
                                                        <label htmlFor='contact_work'>Work No. <span style={{color:"green"}}><strong>[{data['contact_work'] || ''}]</strong></span></label>
                                                        <FormInput
                                                            id='contact_work'
                                                            placeholder='Contact Work'
                                                            onChange={(e) => {
                                                                this.setState({ work:  e.target.value || null })
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col md='4' className='form-group'>
                                                        <label htmlFor='address'>Email</label>
                                                        <FormInput
                                                            id='email'
                                                            disabled
                                                            placeholder='Email'
                                                            type='email'
                                                            value={data['email'] || ''}
                                                            onChange={(e) => {
                                                                this.setState({ email: e.target.value })
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>

                                                <hr/>
                                                
                                                

                                                <label>
                                                    Reset Password ? <input type="checkbox" onChange={handleResetPassword}/> 
                                                </label>
                                                <hr/>
                                                {this.state.reset_password && (
                                                <Row form>
                                                    <Col md='3' className='form-group'>
                                                        <label htmlFor='current_password'>Old Password</label>
                                                        <FormInput
                                                            id='current_password'
                                                            type='password'
                                                            onChange={(e) => {
                                                                this.setState({ current_password:  e.target.value || null })
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col md='3' className='form-group'>
                                                        <label htmlFor='new_password'>New Passowrd</label>
                                                        <FormInput
                                                            id='new_password'
                                                            type='password'
                                                            onChange={(e) => {
                                                                this.setState({ new_password:  e.target.value || null})
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col md='3' className='form-group'>
                                                        <label htmlFor='confirm_password'>Confirm Password</label>
                                                        <FormInput
                                                            id='confirm_password'
                                                            type='password'
                                                            onChange={(e) => {
                                                                this.setState({ confirm_password: e.target.value || null })
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>)}

                                                <hr/>
                                                <Row form>
                                                    <Col md='6' className='form-group'>
                                                        <label htmlFor='sponsor_area'>Service Areas</label>

                                                        {areas && areas.map(area => (
                                                            <div key={data['uuid'] + area['code']}>
                                                                <InputGroup className='mb-3'>
                                                                    <InputGroupAddon type='prepend'>
                                                                        <InputGroupText>{area['service_area_code']}</InputGroupText>
                                                                    </InputGroupAddon>
                                                                    <InputGroupAddon type='prepend'>
                                                                        <InputGroupText>{area['sponsor_area_code']}</InputGroupText>
                                                                    </InputGroupAddon>
                                                                    <FormInput
                                                                        id='sponsor_areas'
                                                                        placeholder=''
                                                                        value={area['description']}
                                                                        disabled
                                                                    />
                                                                </InputGroup>
                                                            </div>
                                                        ))}

                                                    </Col>
                                                </Row>

                                                {this.state.message && (
                                                        <Alert style={{ backgroundColor: this.state.success ? colors.GREEN : colors.RED, color: '#ffffff' }}>
                                                            {this.state.message}
                                                        </Alert>
                                                    )}
                                                {this.state.loading && <p>...Submitting</p>}

                                                <Button
                                                        size='md'
                                                        type='submit'
                                                    >Save</Button>
                                                

                                                {/* <ButtonGroup>
                                                    <Button theme='accent'>Update Profile</Button>
                                                </ButtonGroup> */}

                                            </Form>
                                        </Col>
                                    </Row>
                                </ListGroupItem>
                            </ListGroup>
                        </Card>

                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state,
        serviceAreas: state.serviceAreas
    }
}

export default connect(mapStateToProps)(withRouter(MyAccount));

