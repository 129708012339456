const characters = 'abcdefghijklmnopqrstuvwxyz'.split('');

export const colors = {
    ORANGE: '#EBB748',
    YELLOW: '#EBB748',
    GREEN: '#81A94F',
    LIME: '#81A94F',
    BLUE: '#40A1D8',
    TURQUIOSE: '#818EA3',
    PURPLE: '#7A4CA6',
    LILAC: '#A06BB4',
    RED: '#DD5757',
    LIGHT_ORANGE: '#FAD6B7',
    LIGHT_YELLOW: '#FAE9B7',
    LIGHT_GREEN: '#A9DFBF',
    LIGHT_LIME: '#C5ECD5',
    LIGHT_BLUE: '#ABD7F1',
    LIGHT_TURQUIOSE: '#BBEBFA',
    LIGHT_PURPLE: '#D7B9F3',
    LIGHT_LILAC: '#E4C4F0'
}

export function getColorMatch(char) {
    if (characters.slice(0, 3).includes(char)) {
        return { dark: colors.ORANGE, light: colors.LIGHT_ORANGE }
    } else if (characters.slice(3, 6).includes(char)) {
        return { dark: colors.YELLOW, light: colors.LIGHT_YELLOW }
    } else if (characters.slice(6, 9).includes(char)) {
        return { dark: colors.GREEN, light: colors.LIGHT_GREEN }
    } else if (characters.slice(9, 12).includes(char)) {
        return { dark: colors.LIME, light: colors.LIGHT_LIME }
    } else if (characters.slice(12, 15).includes(char)) {
        return { dark: colors.BLUE, light: colors.LIGHT_BLUE }
    } else if (characters.slice(15, 18).includes(char)) {
        return { dark: colors.TURQUIOSE, light: colors.LIGHT_TURQUIOSE }
    } else if (characters.slice(18, 21).includes(char)) {
        return { dark: colors.PURPLE, light: colors.LIGHT_PURPLE }
    } else {
        return { dark: colors.LILAC, light: colors.LIGHT_LILAC }
    }
}

