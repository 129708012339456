import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";

import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
import MainSidebar from "../components/layout/MainSidebar/MainSidebar";
import moment from "moment-timezone";

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


class DefaultLayout extends React.Component {
    state = {
        user: null
    }

    componentDidMount() {
        const isLoggedInToday = this.props.user && this.props.lastLoggin && this.props.lastLoggin === moment().format('YYYY-MM-DD')
        if (this.props.user && this.props.isLoggedIn && isLoggedInToday) {
            // Do nothing
        } else {
            this.props.history.push('/auth/signin')
        }
    }

    render() {
        return (
            <Container fluid>
                <Row>
                    <MainSidebar />
                    <Col
                        className="main-content p-0"
                        lg={{ size: 10, offset: 2 }}
                        md={{ size: 9, offset: 3 }}
                        sm="12"
                        tag="main"
                    >
                        {!this.props.noNavbar && <MainNavbar />}
                        {this.props.children}
                        {/* {!this.props.noFooter && <MainFooter />} */}
                    </Col>
                </Row>
            </Container>
        )
    }
}

DefaultLayout.propTypes = {
    /**
     * Whether to display the navbar, or not.
     */
    noNavbar: PropTypes.bool,
    /**
     * Whether to display the footer, or not.
     */
    noFooter: PropTypes.bool
};

DefaultLayout.defaultProps = {
    noNavbar: false,
    noFooter: false
};

const mapStateToProps = state => {
    return {
        ...state,
        user: state.user,
        lastLoggin: state.lastLoggin
    }
}

export default connect(mapStateToProps)(withRouter(DefaultLayout));

