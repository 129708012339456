import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouteNavLink } from 'react-router-dom';
import { NavItem, NavLink } from 'shards-react';

const SidebarNavItem = (props) => {
  const item = props.item
  if (item.routes) {
    return (
      <li className='custom-nav-item'>
        <a className='custom-nav-link' onClick={() => props.onRouteActive(item.title)}>
          {item.htmlBefore && (
            <div
              className='d-inline-block item-icon-wrapper custom-nav-icon'
              dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
            />
          )}
          {item.title && <span>{item.title}</span>}
          {item.htmlAfter && (
            <div
              className='d-inline-block item-icon-wrapper custom-nav-icon float-right'
              dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
            />
          )}
        </a>
      </li>
    )
  }

  const style = props.noBorder ? { borderBottom: 'none' } : {}
  return (
    <NavItem>
      <RouteNavLink to={item.redirectTo || item.to} onClick={() => {
        if (props.onToggle) {
          props.onToggle()
        }
      }}>
        <NavLink tag='div' style={style}>
          {item.htmlBefore && (
            <div
              className='d-inline-block item-icon-wrapper'
              dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
            />
          )}
          {item.title && <span>{item.title}</span>}
          {item.htmlAfter && (
            <div
              className='d-inline-block item-icon-wrapper float-right'
              dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
            />
          )}
        </NavLink>
      </RouteNavLink>
    </NavItem>
  )

  return (
    <NavItem>
      <NavLink tag={RouteNavLink} to={item.redirectTo || item.to} style={style}>
        {item.htmlBefore && (
          <div
            className='d-inline-block item-icon-wrapper'
            dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
          />
        )}
        {item.title && <span>{item.title}</span>}
        {item.htmlAfter && (
          <div
            className='d-inline-block item-icon-wrapper float-right'
            dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
          />
        )}
      </NavLink>
    </NavItem>
  )
}

SidebarNavItem.propTypes = {
  /**
   * The item object.
   */
  item: PropTypes.object
};

export default SidebarNavItem;
