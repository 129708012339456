import React from 'react';
import { Redirect } from 'react-router-dom';

// Layout Types
import DefaultLayout from './layouts/DefaultLayout';
import FullPageLayout from './layouts/FullPage';

// Route Views
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Jobs from './pages/Jobs';
import AddEditJob from './pages/AddEditJob';
import MyAccount from './pages/MyAccount';
import Errors from './pages/Errors.jsx';

export default [
  {
    path: '/',
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to='/home' />
  },
  {
    path: '/home',
    layout: DefaultLayout,
    component: Dashboard
  },
  {
    path: '/jobs',
    layout: DefaultLayout,
    component: Jobs,
    exact: true
  },
  {
    path: '/jobs/:uuid',
    layout: DefaultLayout,
    component: AddEditJob,
    exact: true
  },
  {
    path: '/refresh',
    layout: DefaultLayout,
    component: () => <Redirect to='/jobs' />
  },
  {
    path: '/my-account',
    layout: DefaultLayout,
    component: MyAccount
  },
  {
    path: '/auth/signin',
    layout: FullPageLayout,
    component: Login
  },
  {
    layout: DefaultLayout,
    component: Errors
  },
];
