import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, NavbarBrand } from 'shards-react';

import { toggle_sidebar } from '../../../store/action';
import { connect } from 'react-redux';

const IMG_LOGO = require('../../../assets/images/s4biz-white.png').default;

class SidebarMainNavbar extends React.Component {
  state = {}

  handleToggleSidebar() {
    if (this.props.toggle_sidebar) {
      this.props.toggle_sidebar()
    }
  }

  render() {
    const { hideLogoText } = this.props;
    var background_color = this.props.user && this.props.user.sponsor_background_color ? this.props.user.sponsor_background_color : ''

    return (
      <div className='main-navbar'>
        <Navbar
          className='align-items-stretch bg-dark-gray flex-md-nowrap p-0'
          type='light'
        >
          <NavbarBrand
            className='w-100 mr-0'
            href='#'
            style={{ lineHeight: '25px', padding: '8px 0',backgroundColor: background_color  }}
          >
            <div className='d-table m-auto'>
              <img
                id='main-logo'
                className='d-inline-block align-top mr-1'
                style={{ maxWidth: '120px' }}
                src={IMG_LOGO}
                alt='S4Biz Admin'
              />
              {!hideLogoText && (
                <span className='d-none d-md-inline ml-1'>
                  S4Biz Admin
                </span>
              )}
            </div>
          </NavbarBrand>
          {/* eslint-disable-next-line */}
          <a
            className='toggle-sidebar d-sm-inline d-md-none d-lg-none'
            onClick={() => this.handleToggleSidebar()}
          >
            <i className='material-icons'>&#xE5C4;</i>
          </a>
        </Navbar>
      </div>
    );
  }
}

SidebarMainNavbar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};

SidebarMainNavbar.defaultProps = {
  hideLogoText: false
};


const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggle_sidebar: () => dispatch(toggle_sidebar())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMainNavbar);
