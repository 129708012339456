import React from 'react';
import { Row, Col, Card, CardBody, Button, ButtonGroup, InputGroup, FormInput, InputGroupText, InputGroupAddon, DatePicker } from 'shards-react';

import RangeDatePicker from '../common/RangeDatePicker';
import JobStatusFilter from '../jobs/JobStatusFilter';
import ServiceAreaFilter from '../jobs/ServiceAreaFilter';
import PriorityFilter from '../jobs/PriorityFilter';
import DateOrder from '../jobs/DateOrder';
import * as moment from 'moment-timezone';

class JobActions extends React.Component {
    state = {
        open: false,
        dates: {},
        title: '',
        search: '',
        orderBy: null,
        showStatusFilter: false,
        showServiceFilter: false,
        showPriorityFilter: false,
        showOrderFilter: false,
        selected: [],
        appointmentDate: null,
        area: null,
        status: null,
        priority: null,
        length: 0
    }

    componentDidUpdate(props) {
        if (this.props.selected !== props.selected) {
            this.setState({
                selected: this.props.selected
            })
        }

        if (this.props.length !== props.length) {
            this.setState({
                length: this.props.length
            })
        }

        if (this.props.dates && this.props.dates !== props.dates) {
            this.setState({
                dates: this.props.dates
            })
        }

        if (this.props.currentState && this.props.currentState !== props.currentState) {
            const currentState = this.props.currentState
            this.setState({
                area: currentState.area || null,
                status: currentState.status || null,
                agent: currentState.agent || null,
                priority: currentState.priority || null,
                search: currentState.search || '',
                orderBy: currentState.orderBy || null,
            })
        }

        if (this.props.refresh && this.props.refresh !== props.refresh) {
            this.setState({
                area: null,
                status: null,
                agent: null,
                priority: null,
                orderBy: null,
                search: ''
            })
        }
    }

    toggle(title) {
        if (title === 'showUpdate') {
            this.props.onUpdateMultiple()
        } else {
            const state = {
                showStatusFilter: false,
                showServiceFilter: false,
                showPriorityFilter: false,
                showSponsorFilter: false,
                showOrderFilter: false
            }
            if (title) {
                state[title] = !this.state[title]
            }
            this.setState(state);
        }
    }

    render() {
        const hasSelected = this.state.selected.length > 0
        var appointmentDate = this.state.appointmentDate || ''
        if (this.state.appointmentDate) {
            appointmentDate = moment(this.state.appointmentDate).format('DD MMM YYYY')
        }
        return (
            <div className='job-actions'>
                <Card small className='h-100 no-border'>
                    <CardBody className='pt-0 pb-0 '>
                        <Row className='border-bottom py-3 bg-light'>
                            <Col sm='12' md='6' lg='6' className='d-flex'>
                                <RangeDatePicker
                                    dates={this.state.dates}
                                    onStartAt={(startAt) => {
                                        this.props.onStartAt(startAt)
                                    }}
                                    onEndAt={(endAt) => {
                                        this.props.onEndAt(endAt)
                                    }}
                                />
                            </Col>
                            <Col sm='6' md='3' lg='3'>
                                <InputGroup className='d-flex my-auto date-range'>
                                    <DatePicker
                                        dateFormat="yyyy-MM-dd"
                                        selected={this.state.appointmentDate}
                                        onChange={(value) => {
                                            this.setState({
                                                appointmentDate: value
                                            })
                                            this.props.onAppointmentAt(moment(value).format('YYYY-MM-DD'))
                                        }}
                                        placeholderText={this.state.startDate || 'Appointment Date'}
                                        dropdownMode='select'
                                        value={appointmentDate}
                                        className='text-center'
                                    />
                                    <InputGroupAddon type='append'>
                                        <InputGroupText>
                                            <i className='material-icons'>&#xE916;</i>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </Col>

                            <Col sm='5' md='3' lg='3'>
                                <InputGroup size="lg" className="ml-auto">
                                    <FormInput placeholder="Search job no." value={this.state.search} style={{ width: '100%' }} onChange={e => {
                                        this.setState({ search: e.target.value })
                                        this.props.onSearch(e.target.value)
                                    }} />
                                </InputGroup>
                            </Col>
                        </Row>
                        {/* Filters */}
                        <Row className='border-bottom py-3'>
                            <Col sm='12' md='12' lg='6' className='mb-2'>
                                <ButtonGroup className='d-inline-flex'>
                                    {hasSelected && (
                                        <Button
                                            size='sm'
                                            className='btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                            onClick={() => this.toggle('showUpdate')}
                                        >
                                            <span style={{ fontSize: '0.75rem' }}>Update Multiple</span>
                                            {this.state.status ? <span style={{ fontSize: '0.75rem' }}></span> : <span style={{ fontSize: '0.75rem' }}></span>}
                                        </Button>
                                    )}
                                    {/* Job Status */}
                                    {!hasSelected && (<div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                                        <Button
                                            size='sm'
                                            className='btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                            onClick={() => this.toggle('showStatusFilter')}
                                        >
                                            <span style={{ fontSize: '0.75rem' }}>{hasSelected ? 'Set Status' : this.state.status || 'Status Filter'} &darr;</span>
                                            {this.state.status ? <span style={{ fontSize: '0.75rem' }}></span> : <span style={{ fontSize: '0.75rem' }}></span>}
                                        </Button>
                                        <JobStatusFilter visible={this.state.showStatusFilter} onSelect={(filter) => {
                                            this.props.onFilterStatus(filter.value)
                                            this.setState({ showStatusFilter: false, status: filter.value ? filter.label : null })
                                        }} status={this.state.status} />
                                    </div>)}
                                    {/* Service Area Filter */}
                                    {!hasSelected && (<div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                                        <Button
                                            size='sm'
                                            className='btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                            onClick={() => this.toggle('showServiceFilter')}
                                        >
                                            <span style={{ fontSize: '0.75rem' }}>{this.state.serviceArea || 'Service Area Filter'} &darr;</span>
                                            {this.state.serviceArea ? <span style={{ fontSize: '0.75rem' }}></span> : <span style={{ fontSize: '0.75rem' }}></span>}
                                        </Button>
                                        <ServiceAreaFilter visible={this.state.showServiceFilter} onSelect={(filter) => {
                                            this.props.onFilterService(filter.value)
                                            this.setState({ showServiceFilter: false, serviceArea: filter.value ? filter.description : null })
                                        }} />
                                    </div>)}
                                </ButtonGroup>
                            </Col>

                            <Col sm='12' md='12' lg='6' className='mb-2 mb-sm-0'>
                                <ButtonGroup className='d-inline-flex'>
                                    {/* Order By Filter */}
                                    {!hasSelected && (<div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                                        <Button
                                            size='sm'
                                            className='btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                            onClick={() => this.toggle('showOrderFilter')}
                                        >
                                            <span style={{ fontSize: '0.75rem' }}>{this.state.orderBy || 'Order By'} </span>
                                            {this.state.orderBy ? <span style={{ fontSize: '0.75rem' }}></span> : <span style={{ fontSize: '0.75rem' }}></span>}
                                        </Button>
                                        <DateOrder visible={this.state.showOrderFilter} onSelect={(filter) => {
                                            this.props.onFilterOrderBy(filter.value)
                                            this.setState({ showOrderFilter: false, orderBy: filter.value ? filter.title : null })
                                        }} />
                                    </div>)}
                                    {/* Priority Filter */}
                                    {!hasSelected && (<div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                                        <Button
                                            size='sm'
                                            className='btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                            onClick={() => this.toggle('showPriorityFilter')}
                                        >
                                            <span style={{ fontSize: '0.75rem' }}>{this.state.priority || 'Priority Filter'} &darr;</span>
                                            {this.state.priority ? <span style={{ fontSize: '0.75rem' }}></span> : <span style={{ fontSize: '0.75rem' }}></span>}
                                        </Button>
                                        <PriorityFilter visible={this.state.showPriorityFilter} onSelect={(filter) => {
                                            this.props.onFilterPriority(filter)
                                            this.setState({ showPriorityFilter: false, priority: filter.value ? filter.title : null })
                                        }} />
                                    </div>)}
                                    {/* Clear All Filters */}
                                    {!hasSelected && (<div className='d-block' style={{ position: 'relative', marginRight: '8px' }}>
                                        <Button
                                            size='sm'
                                            className='btn-primary ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0'
                                            onClick={() => {
                                                this.toggle()
                                                this.props.onClearAll()
                                            }}
                                        >
                                            <span style={{ fontSize: '0.75rem' }}>Clear All</span>
                                        </Button>
                                    </div>)}
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

export default JobActions;
