import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Col } from 'shards-react';

const PageTitle = ({ title, subtitle, className, onBack, ...attrs }) => {
  const classes = classNames(
    className,
    'text-center',
    'text-md-left',
    'mb-sm-0'
  );

  return (
    <div style={{ cursor: 'pointer' }} onClick={() => {
      if (onBack) {
        onBack()
      }
    }}>
      <Col xs='12' sm='4' className={classes} {...attrs}>
        <span className='text-uppercase page-subtitle'>{title}</span><br />
        <span className='text-uppercase'>{subtitle}</span>
      </Col>
    </div>
  )
};

PageTitle.propTypes = {
  /**
   * The page title.
   */
  title: PropTypes.string,
  /**
   * The page subtitle.
   */
  subtitle: PropTypes.string
};

export default PageTitle;
