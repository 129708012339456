import React from 'react';
import { Link } from 'react-router-dom';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,

  NavLink
} from 'shards-react';

export default class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }

  render() {
    return (
      <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
        <DropdownToggle caret tag={NavLink} className='text-nowrap px-3'>
          <img
            className='user-avatar rounded-circle mr-2'
            src={require('./../../../../assets/images/avatars/demo.png').default}
            style={{ maxWidth: '2.5rem', height: '2.5rem' }}
            alt='User Avatar'
          />{' '}
          <span className='d-none d-md-inline-block'>{this.props.user ? this.props.user['full_name'] : 'Demo Account'}</span>
        </DropdownToggle>
        { <Collapse tag={DropdownMenu} right small open={this.state.visible}>
          <DropdownItem tag={Link} to='my-account'>
            <i className='material-icons'>&#xE7FD;</i>Account
          </DropdownItem>
          {/* <DropdownItem divider />
          <DropdownItem tag={Link} to='/' className='text-danger'>
            <i className='material-icons text-danger'>&#xE879;</i> Logout
          </DropdownItem> */}
          </Collapse> }
      </NavItem>
    );
  }
}
